<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size+'px'"  viewBox="0 0 100 100">
        <path :fill="color" id="fill" d="M-1065.337,243.961a5.93,5.93,0,0,0-5.934,5.923,5.93,5.93,0,0,0,5.924,5.934,5.928,5.928,0,0,0,5.933-5.924v0a5.837,5.837,0,0,0-5.745-5.928Zm-26.106,7.444a24.712,24.712,0,0,0-24.707,24.717,24.712,24.712,0,0,0,24.717,24.707,24.713,24.713,0,0,0,24.708-24.707,24.759,24.759,0,0,0-24.718-24.737Zm0,40.476a15.76,15.76,0,0,1-15.754-15.764,15.759,15.759,0,0,1,15.764-15.753,15.758,15.758,0,0,1,15.754,15.758,15.765,15.765,0,0,1-15.764,15.738Zm49.81-36.316a30.139,30.139,0,0,0-30.139-30.139h-39.721a30.14,30.14,0,0,0-30.14,30.139v39.722a30.14,30.14,0,0,0,30.14,30.139h39.721a30.139,30.139,0,0,0,30.139-30.139Zm-9.455,39.722a20.619,20.619,0,0,1-20.552,20.684h-39.853a20.613,20.613,0,0,1-20.679-20.547V255.565a20.607,20.607,0,0,1,20.536-20.679h39.864a20.613,20.613,0,0,1,20.684,20.542v39.859Z" transform="translate(1141.633 -225.426)" />
    </svg>
</template>
<script>
    export default {
        name: 'iconInstagram',
        props: {
            size: {
                type: Number,
                default: 20,
            },
            color: {
                type: String,
                default: 'var(--main-navy)'
            }
        }
    }
</script>